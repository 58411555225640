"use client";

import { LinkSolstice } from "@/components/shared/LinkSolstice/LinkSolstice";
import styles from "./HeaderMenu.module.scss";
import { useEffect, useRef, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import IconCross from "@/images/icons/cross.svg";
import IconCaret from "@/images/icons/caret-down.svg";
import IconArrowLongLeft from "@/images/icons/arrow-long-left.svg";
import clsx from "clsx";
import { breakpoint } from "@/utilities/breakpoints";
import { useBreakpoint } from "@/hooks/useViewportDimensions";
import { ButtonLink } from "@/components/shared/Button/Button";
import { SiteName, siteName } from "@/utilities/constants";

export type NavItem = {
	id: string;
	label: string;
	href: string;
	displayInHeader?: boolean;
};

export type NavParent = {
	id: string;
	label: string;
	children: Array<NavItem>;
};

type NavReactNode = {
	render: React.ReactNode;
};

export namespace HeaderMenu {
	export type Props = {
		navItems: Array<NavParent>;
		className?: string;
		isMobileExpanded: boolean;
		setIsMobileExpanded: (v: boolean) => void;
	};
}

export const HeaderMenu = ({
	navItems,
	className,
	isMobileExpanded,
	setIsMobileExpanded,
}: HeaderMenu.Props) => {
	const [expandedNavItem, setExpandedNavItem] = useState<string | null>(null);
	const isMobile = !useBreakpoint("xl", true);

	useEffect(() => {
		if (typeof document !== "undefined") {
			document.documentElement.classList.toggle("prevent-scroll", isMobile && isMobileExpanded);
		}
	}, [isMobileExpanded, isMobile]);

	useEffect(() => {
		function handleClickOutside(event: MouseEvent) {
			if (expandedNavItem) {
				if (
					(event.target as HTMLElement).closest(`[data-parent-wrapper="${expandedNavItem}"]`) ===
						null &&
					breakpoint("lg")
				) {
					setExpandedNavItem(null);
				}
			}
		}

		if (expandedNavItem) {
			if (menuRef.current) {
				menuRef.current.scrollTop = 0;
			}
			document.addEventListener("click", handleClickOutside);
		}
		return () => {
			document.removeEventListener("click", handleClickOutside);
		};
	}, [expandedNavItem]);

	useEffect(() => {
		if (typeof document !== "undefined") {
			function onScroll() {
				setExpandedNavItem(null);
			}

			if (!isMobile && expandedNavItem) {
				document.body.addEventListener("scroll", onScroll);
			}

			return () => {
				document.body.removeEventListener("scroll", onScroll);
			};
		}
	}, [expandedNavItem, isMobile]);

	const menuRef = useRef<HTMLDivElement>(null);

	return (
		<motion.div
			ref={menuRef}
			className={clsx(
				styles["header-menu"],
				className,
				isMobile && expandedNavItem && "overflow-hidden",
				isMobileExpanded && styles["header-menu--mobile-expanded"],
			)}
			style={{ transition: isMobile ? "0.4s ease-in-out" : undefined }}
		>
			<div className={styles["header-menu__mobile-header"]}>
				<button
					className={styles["header-menu__close"]}
					onClick={() => {
						setIsMobileExpanded(false);
						setExpandedNavItem(null);
					}}
				>
					<span className="sr-only">Close Navigation Menu</span>
					<IconCross />
				</button>
			</div>
			<ul className={styles["header-menu__list"]}>
				{navItems.map((item, index) => (
					<li
						className={styles["header-menu__item"]}
						key={index}
					>
						{"children" in item && (
							<div
								className={styles["header-menu__parent-wrapper"]}
								data-parent-wrapper={item.id}
							>
								<button
									className={clsx(
										styles["header-menu__parent"],
										expandedNavItem === item.id && styles["header-menu__parent--expanded"],
									)}
									onClick={() => setExpandedNavItem(expandedNavItem === item.id ? null : item.id)}
									aria-expanded={expandedNavItem === item.id ? "true" : "false"}
									aria-haspopup="menu"
								>
									{item.label}
									<IconCaret className={styles["header-menu__parent-caret"]} />
								</button>
								<AnimatePresence>
									{expandedNavItem === item.id && (
										<motion.div
											className={styles["header-submenu"]}
											initial={isMobile ? { transform: "translateX(100%)" } : { opacity: 0 }}
											animate={isMobile ? { transform: "translateX(0%)" } : { opacity: 1 }}
											exit={isMobile ? { transform: "translateX(100%)" } : { opacity: 0 }}
											transition={{ duration: isMobile ? 0.4 : 0.2, ease: "easeInOut" }}
										>
											<button
												className={styles["header-submenu__back"]}
												onClick={() => setExpandedNavItem(null)}
												aria-label=""
											>
												<IconArrowLongLeft />
											</button>
											<div className={styles["header-submenu__heading"]}>{item.label}</div>
											<ul
												className={styles["header-submenu__list"]}
												aria-label={item.label}
											>
												{item.children
													.filter(v => !!v.displayInHeader)
													.map((child, index) => (
														<li
															className={styles["header-submenu__item"]}
															key={index}
														>
															<LinkSolstice
																className={styles["header-submenu__link"]}
																href={child.href}
															>
																<div className={styles["header-submenu__link-inner"]}>
																	{child.label}
																	<IconArrowLongLeft />
																</div>
															</LinkSolstice>
														</li>
													))}
											</ul>
										</motion.div>
									)}
								</AnimatePresence>
							</div>
						)}
					</li>
				))}
			</ul>

			<div className={styles["header-menu__footer"]}>
				<div className={styles["header-menu__footer-actions"]}>
					{process.env.NEXT_PUBLIC_SITE === SiteName.Solstice && (
						<ButtonLink
							href={"/apply-online"}
							label="Join Now"
						/>
					)}
				</div>
				<div className={styles["header-menu__footer-links"]}>
					<LinkSolstice
						className={styles["header-menu__link"]}
						href={"https://tasgasnetworks.com.au/network-alerts"}
					>
						Gas Network Alerts
					</LinkSolstice>
					<div className={styles["header-menu__v-bar"]} />
					<LinkSolstice
						className={styles["header-menu__link"]}
						href={"/contact-us"}
					>
						Contact us
					</LinkSolstice>
				</div>
				<div className={styles["header-menu__footer-links"]}>
					<a
						className={clsx(
							styles["header-menu__link"],
							styles["header__secondary-link--large"],
							siteName === SiteName.Solstice && styles["header-menu__active-region"],
						)}
						href="/"
					>
						TAS
					</a>
					{" / "}
					<a
						className={clsx(
							styles["header-menu__link"],
							styles["header__secondary-link--large"],
							siteName === SiteName.SolsticeVictoria && styles["header-menu__active-region"],
						)}
						href="/vic"
						onClick={
							process.env.NODE_ENV === "development"
								? e => {
										if (
											!confirm(
												"You are being redirected/rewritten to a staging/production VIC site. If you want to access the local VIC site, build and run it locally.\r\n\r\nDo you want to continue?",
											)
										) {
											e.preventDefault();
										}
									}
								: undefined
						}
					>
						VIC
					</a>
				</div>
			</div>
		</motion.div>
	);
};
